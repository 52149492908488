<script setup>
  /**
   * 
   * Komponens: a simple layout használja
   * 
   * 
  */
  // nyelvi es routing beallitasok
  const localePath = useLocalePath();
  // const { locale } = useI18n();
  // const route = useRoute();

</script>

<template>
  <header class="max-w-screen">
    <!-- border-b border-navbg-dark -->
    <div
      class="w-full top-0 left-0 bg-boxtxt-dark shadow-xl"
      style="box-shadow: 10px 0 10px rgba(0, 0, 0, 0.25)"
    >
      <!-- <TopBar /> -->
      <div
        class="sm:mx-auto md:mx-auto lg:mx-auto xl:mx-auto md:container lg:container xl:container flex justify-between"
      >
        <div class="py-4 relative px-2 flex lg:px-0 xl:px-0">
          <div class="flex-shrink-0 flex items-center">
            <nuxt-link :to="localePath('index')" class="outline-none"
              ><LazySvgLogoV2 class="w-auto h-20 xs:h-12 sm:h-12"
            /></nuxt-link>
          </div>
        </div>
        <div class="py-4 relative text-txt-light"></div>
      </div>
    </div>
  </header>
</template>
