<script setup>
  const localePath = useLocalePath();
</script>

<template>
  <div>
    <UserGroupAlert />
    <WebshopPageHeader />
    <!-- <Nuxt /> -->
    <div class="xs:mx-2 sm:mx-2">
      <slot />
    </div>
    <Footer />
    <UiBackToTopButton />
    <AuthForgetPasswordModal />
    <WebshopModalCart />
    <!-- TODO átírni -->
    <!-- <ModalWebshopCurrencyModifiedInfo /> -->
    <!-- <ModalAuthForgetPassword /> -->
    <!-- <CookieBanner /> -->
    <LazyUiSnackBar/>
  </div>
</template>
